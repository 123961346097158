import { useState, useContext } from 'react'
import Error from './Error'
import { UserContext } from '../contexts/UserContext'

function LoginForm () {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const {setUser} = useContext(UserContext)

    function handleSubmit(e){
        e.preventDefault()
        setIsLoading(true)
        fetch('/login', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, password })
        }).then((r) => {
            setIsLoading(false)
            if (r.ok) {
                r.json().then((user) => setUser(user))
            } else {
                r.json().then((err) => setErrors(err.errors))
            }
        })
    }

    return(
        <form onSubmit={handleSubmit}>
            <label>
                Username
                <input
                name="username"
                type="text"
                autoComplete="off"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                ></input>
            </label>
            <label>
                Password
                <input
                name="password"
                type="password"
                autoComplete="off"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                ></input>
            </label>

            <button className="login-btn" type="submit">{isLoading? "Loading..." : "Login"}</button>

                {errors.map((err) => (
                    <Error key={err}>{err}</Error>
                ))}
        </form>
    )
}

export default LoginForm